import axios from 'axios';
import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import '../../../assets/css/main.scss';
import { PortfolioProvider } from '../../../context/context';
import {
  animationConfig,
  animationConfigSeeAllbtn,
  bootstrapBreakpoints,
  contactData,
  headerData,
} from '../../../mock/data';
import { scrollUtil } from '../../../utils/helpers';
import Footer from '../Footer';
import GDPR from '../GDPR';
import Header from '../Header';
import checkLastPostDate from './utils/checkLastPostDate';

export const GlobalStyle = createGlobalStyle`
	a{
		cursor: pointer !important;
	}
	@font-face {
		font-family: 'Darwin Pro';
		src: 
			url('/fonts/DarwinPro/regular/DarwinPro-Regular.woff2') format('woff2'),
			url('/fonts/DarwinPro/regular/DarwinPro-Regular.woff') format('woff'),
			url('/fonts/DarwinPro/regular/DarwinPro-Regular.otf') format('opentype'),
			url('/fonts/DarwinPro/regular/DarwinPro-Regular.ttf') format('truetype'),
			url('/fonts/DarwinPro/regular/DarwinPro-Regular.svg#DarwinPro-Regular') format('svg');
		font-weight: normal;
		font-style: normal;
		font-display: block;
	}

	@font-face {
		font-family: 'Darwin Pro';
		src: 
		url('/fonts/DarwinPro/bold/DarwinPro-Bold.woff2') format('woff2'),
		url('/fonts/DarwinPro/bold/DarwinPro-Bold.woff') format('woff'),
			url('/fonts/DarwinPro/bold/DarwinPro-Bold.otf') format('opentype'),
			url('/fonts/DarwinPro/bold/DarwinPro-Bold.ttf') format('truetype'),
			url('/fonts/DarwinPro/bold/DarwinPro-Bold.svg#DarwinPro-Bold') format('svg');
		font-weight: bold;
		font-style: bold;
		font-display: block;
	}

  @font-face {
		font-family: 'Darwin Light';
		src: 
		url('/fonts/DarwinPro/light/DarwinPro-Light.woff2') format('woff2'),
		url('/fonts/DarwinPro/light/DarwinPro-Light.woff') format('woff'),
			url('/fonts/DarwinPro/light/DarwinPro-Light.otf') format('opentype'),
			url('/fonts/DarwinPro/light/DarwinPro-Light.ttf') format('truetype'),
			url('/fonts/DarwinPro/light/DarwinPro-Light.svg#DarwinPro-Light') format('svg');
		font-weight: light;
		font-style: light;
		font-display: block;
	}

* {
    font-family: 'Darwin Pro';
  }
	
`;

interface ILayout {
  children: any;
  discret?: boolean;
  hash?: string;
}

const Layout = (props: ILayout) => {
  const { children, discret, hash } = props;
  const isDiscret = discret || false;
  const defaultAlert = {
    variant: 'primary',
    message: '',
    isVisible: false,
  };

  const [alert, setAlert] = React.useState(defaultAlert);

  const [isHomePage, setIsHomePage] = React.useState(false);

  const [isBlog, setIsBlog] = React.useState(false);

  const getStoredPosts = () => {
    if (typeof window !== 'undefined') {
      const key = 'posts';
      const stringValue = sessionStorage.getItem(key);
      if (stringValue !== null) {
        const value = JSON.parse(stringValue);
        const expirationDate = new Date(value.expirationDate);
        if (expirationDate > new Date()) {
          return value.posts;
        }
        sessionStorage.removeItem(key);
      }
    }
    return [];
  };

  const setStoredPosts = (inputPosts) => {
    if (typeof window !== 'undefined') {
      const expirationDate = new Date(new Date().getTime() + 60000 * 60 * 4);
      const posts = {
        posts: inputPosts,
        expirationDate: expirationDate.toISOString(),
      };
      sessionStorage.setItem('posts', JSON.stringify(posts));
    }
  };

  const [posts, setPosts] = React.useState(getStoredPosts());

  useEffect(() => {
    if (!posts.length) {
      const mediumURL = process.env.GATSBY_URL_MEDIUM;
      axios
        .get(mediumURL)
        .then((res) => {
          if (res?.data?.length && checkLastPostDate(res.data[0].pubDate)) {
            setPosts(res.data);
            setIsBlog(true);
            setStoredPosts(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (checkLastPostDate(posts[0].pubDate)) {
      setIsBlog(true);
    }
  }, []);
  const isChrome =
    typeof navigator !== 'undefined'
      ? /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
      : false;

  useEffect(() => {
    if (isChrome && hash) {
      setTimeout(() => {
        scrollUtil(hash);
      }, 1000);
    }
  }, []);

  return (
    <PortfolioProvider
      value={{
        header: headerData,
        contact: contactData,
        animationConfig,
        animationConfigSeeAllbtn,
        bootstrapBreakpoints,
        alert,
        setAlert,
        isHomePage,
        setIsHomePage,
        isBlog,
        posts,
      }}
    >
      <GlobalStyle />
      {!isDiscret && <Header isBlog={isBlog} />}
      {children}
      {!isDiscret && <Footer />}
      <GDPR />
    </PortfolioProvider>
  );
};

export default Layout;
