import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useContext, useState } from 'react';
import { Nav, Navbar, Modal } from 'react-bootstrap';
import { Link as LinkScroll } from 'react-scroll';
import { createPopup } from '@typeform/embed';
import PortfolioContext from '../../../context/context';
import { sendEvent } from '../../../utils/ga4';
import Alert from '../Alert';
import Icon from '../Icon';
import MainWidth from '../MainWidth';
import Form from '../../Form';
import '@typeform/embed/build/css/popup.css';

gsap.registerPlugin(ScrollTrigger);

const Header = ({ isBlog = false }: { isBlog: boolean }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const sectionsOffset = ['about', 'projects'];

  const toggleNav = () => {
    setIsExpanded(!isExpanded);
  };

  const closeNav = () => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  };

  const HandleCtaClick = () => {
    sendEvent({
      action: "Click",
      category: "StartProject on Header",
      label: "Button"
    });

    handleOpenModal();

    // createPopup(process.env.GATSBY_CTA_FORM, {
    //   open: 'time',
    //   openValue: 100,
    //   size: 75,
    // });

    // closeNav();
  };

  const { header, isHomePage } = useContext(PortfolioContext);

  React.useEffect(() => {
    gsap.from('.header', {
      duration: 0.1,
      opacity: 0,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.header',
        // markers: true,
        start: 'top 100%',
        end: 'bottom 0%',
        toggleActions: 'play none none none',
      },
    });
  }, []);

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  return (
    <section id="header" className="fixed-top header">
      <div className="header__inner">
        <MainWidth>
          <Navbar
            expanded={isExpanded}
            expand="xl"
            className="header__navbar my-3 justify-content-between"
          >
            {isHomePage ? (
              <LinkScroll to="hero" spy smooth duration={500}>
                <div className="header__logo-holder">
                  <Icon className="header__logo-img" name="logo-craftable" />
                </div>
                <div className="header__text-holder">
                  <Icon className="header__text-img" name="craftable-text" />
                </div>
              </LinkScroll>
            ) : (
              <Link to="/">
                <div className="header__logo-holder">
                  <Icon className="header__logo-img" name="logo-craftable" />
                </div>
                <div className="header__text-holder">
                  <Icon className="header__text-img" name="craftable-text" />
                </div>
              </Link>
            )}
            <Navbar.Toggle
              onClick={() => toggleNav()}
              aria-controls="responsive-navbar-nav"
              className="hamburger-button"
            >
              <span />
              <span />
              <span />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav" className="flex-grow-0">
              <Nav className="mr-auto" />
              <Nav className="header__menu-holder">
                {header.menu.map((m, i) => {
                  let offset = -100;
                  if (sectionsOffset.includes(m.id)) {
                    offset = -170;
                  } else if (m.id === 'blog' || m.id === 'careers') {
                    offset = -100;
                  }
                  if (m.id === 'blog' && !isBlog) {
                    return null;
                  }
                  return (
                    <h3 key={m.idx} className="header__menu-holder__h3">
                      {isHomePage ? (
                        <LinkScroll
                          className="header__menu-holder__lnk"
                          to={m.id}
                          key={m.id}
                          href={`/#${m.id}`}
                          spy
                          hashSpy={i > 0}
                          offset={offset}
                          smooth
                          duration={500}
                          onClick={() => closeNav()}
                        >
                          {m.name}
                        </LinkScroll>
                      ) : (
                        <a
                          key={m.id}
                          className="header__menu-holder__lnk "
                          href={`/#${m.responsive_id}`}
                        >
                          {m.name}
                        </a>
                      )}
                    </h3>
                  );
                })}
                {header.startProject.map((m) => (
                  <h3 key={m.idx} className="header__menu-holder__h3">
                    <button onClick={() => HandleCtaClick()} className="header__menu-holder__btn">
                      {m.name}
                    </button>
                  </h3>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </MainWidth>
      </div>
      <MainWidth>
        <Alert />
      </MainWidth>
      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
        <Form onClose={handleCloseModal}/>
      </Modal>
    </section>
  );
};

export default Header;
