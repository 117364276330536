export const isBlank = (string: string): boolean =>  {
    return (!string || /^\s*$/.test(string));
}

export const scrollUtil = (hash: string): void =>  {
    const containerId = `${hash.replace('#','')}-link`;
    const element = document.getElementById(containerId) as HTMLDivElement;
    const html  = document.querySelector('html') as HTMLElement;
    if(element && html){ 
        html.classList.add('smooth');
        element.click();
    }
}