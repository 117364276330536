const checkLastPostDate = (inputDate: string): boolean => {
  const d2 = new Date();
  let postDate = inputDate;
  if (postDate.split(' ').length === 2) {
    postDate += `,  ${d2.getFullYear()}`;
  }
  const d1 = new Date(postDate);
  let months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 12;
};

export default checkLastPostDate;
