import React, { useRef } from 'react';

interface Props {
  register: any;
  name: string;
  rules?: any;
}

const OtherCheckbox = ({
  register,
  name,
  rules = null,
}: Props) => {
  const inputText = useRef<HTMLInputElement>();

  const handleBoxCheck = (e: any) => {
    if (inputText.current) console.log("Input exists")
    if (e.target.checked && inputText.current)
      inputText.current.focus();
  }

  return (
    <label className="checkbox checkbox-other pointer">
      <p>Other</p>
      <input
        type="checkbox"
        name={name}
        id="other"
        value="other"
        onClick={(e) => handleBoxCheck(e)}
        {...register(name, rules)}
      />
      <input
        ref={inputText}
        type="text"
        placeholder="Type your answer"
        autoComplete="off"
        {...register("other_area", rules)}
      />
    </label>
  )
}

export default OtherCheckbox;