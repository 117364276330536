import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Modal } from 'react-bootstrap';
import { Link } from 'gatsby';
import CloseIcon from '../../../assets/icons/closeFormButton.inline.svg';

const GDPR = () => {
  const cookie = Cookies.get('rejectCookie');
  const scripts = typeof window === 'undefined' || !window.document ? [] : Array.from(document.querySelectorAll('script[src]'));
  const regexList = [/(google)+/g,/(hotjar)+/g];

  const [confirmedCookiesChoice, setConfirmedCookiesChoice] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(true);
  
  const removeScripts = () => {
    scripts.map((item) => {
      for(const rgx of regexList) {
        if(rgx.test(item['src']))
          item.parentNode.removeChild(item);
      }
    });
  }
  
  //User accepted cookies
  if(cookie === 'false' || confirmedCookiesChoice) return null;
  
  //User rejected cookies
  if(cookie === 'true') {
    removeScripts();
    return null;
  }
  
  const handleAcceptCookies = () => {
    Cookies.set('rejectCookie', 'false', { expires: 365 });
    setConfirmedCookiesChoice(true);
  }

  const handleRejectCookies = () => {
    Cookies.set('rejectCookie', 'true', { expires: 365 });
    removeScripts();
    setConfirmedCookiesChoice(true);
  }

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  return (
    <div className='gdpr'>
      <p>We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.</p>
      <button type='button' className='gdpr__btn' onClick={handleOpenModal}>Cookies Settings</button>
      <button type='button' className='gdpr__btn'onClick={handleAcceptCookies}>Accept Cookies</button>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <div className="gdpr__closeButton" onClick={handleCloseModal}>
          <CloseIcon width="16" height="16" />
        </div>
        <div className="gdpr__modal">
          <h3>Privacy Preference Center</h3>
          <p>When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer. <Link to="/privacy-policy#cookies" onClick={handleCloseModal}>More information.</Link></p>

          <h3>Manage consent preferences</h3>
          <table className="choices">
            <tbody>
              <tr>
                <td><span>Analytics</span></td>
                <td><input type="checkbox" checked={checked} onChange={() => setChecked(prev => !prev)}/></td>
              </tr>
              <tr>
                <td><span>Strictly necessary</span></td>
                <td><span>Always on</span></td>
              </tr>
            </tbody>
          </table>
          <div className="buttons">
            <button type='button' className='gdpr__btn' onClick={handleRejectCookies}>Reject All</button>
            <button type='button' className='gdpr__btn'onClick={handleAcceptCookies}>Confirm my choices</button>
          </div>
        </div>
      </Modal>
    </div>
  )
};

export default GDPR;