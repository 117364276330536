import { nanoid } from 'nanoid';

const year = new Date().getFullYear();
export const allRights = `Craftable ${year}. All Rights Reserved`;

export const socialLinks = [
  {
    id: nanoid(),
    icon: 'linkedin',
    url: 'https://www.linkedin.com/company/craftablesoftware',
    name: 'LinkedIn',
  },
  {
    id: nanoid(),
    icon: 'medium',
    url: 'https://blog.craftablesoftware.com',
    name: 'Medium',
  },
  {
    id: nanoid(),
    icon: 'twitter',
    url: 'https://twitter.com/craftablesw',
    name: 'XTwitter',
  },
];

export const policyLinks = [
  {
    id: nanoid(),
    linkTo: '/privacy-policy#privacy-policy',
    name: `Privacy Policy`,
    containerId: 'privacy-policy-link',
  },
  {
    id: nanoid(),
    linkTo: '/privacy-policy#terms-of-service',
    name: `Terms and Conditions`,
    containerId: 'terms-of-service-link',
  },
];
