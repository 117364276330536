import React from 'react';
import { Link } from 'gatsby';
import { Link as LinkScroll } from 'react-scroll';

import MainWidth from '../MainWidth';
import Icon from '../Icon';

import { socialLinks, policyLinks, allRights } from './data';

const Footer = () => {
  return (
    <section id="footer">
      <MainWidth>
        <div className="hidden_words">
          <p className="hidden__Keywords">boutique software</p>
          <p className="hidden__Keywords">software house porto</p>
          <p className="hidden__Keywords">software house aveiro</p>
          <p className="hidden__Keywords">nearshore</p>
        </div>
        <div id="footer-grid">
          <div className="footer-grid-unit">
            <div className="footer-grid-wrapper">
              <LinkScroll to="hero" className="footer_logo">
                <Icon name="logo-text" />
              </LinkScroll>
              <p className="footer_rights-txt">{allRights}</p>
            </div>
          </div>
          <div className="footer-grid-unit">
            <div className="footer-grid-wrapper">
              <p className="footer_rights-txt">Follow Us</p>
              <div className="d-flex">
                {socialLinks.map((socialLink) => (
                  <a
                    key={socialLink.id}
                    title={socialLink.name}
                    href={socialLink.url}
                    target="_blank"
                    rel="noreferrer"
                    className="footer_social-link"
                  >
                    <i className="footer_icon-holder">
                      <Icon name={socialLink.icon} />
                    </i>
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="footer-grid-unit">
            <div className="footer-grid-wrapper">
              <p className="footer_rights-txt">Co-financed by</p>
              <a href="/pdf/ATE_Template_FichaProjeto_v03_CraftableSoftware.pdf" target="_blank">
                <Icon name="ate_logo" />
              </a>
            </div>
          </div>
          <div className="footer-grid-unit">
            <div className="footer-grid-wrapper">
              {policyLinks.map((policyLink) => (
                <Link
                  id={policyLink.containerId}
                  key={policyLink.id}
                  to={policyLink.linkTo}
                  className="policy_links"
                >
                  {policyLink.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </MainWidth>
    </section>
  );
};

export default Footer;
