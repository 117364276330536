import React, { ReactNode } from 'react';
import OtherCheckbox from '../OtherCheckBox';

interface Props {
  register: any;
  title: ReactNode | string;
  button1: {
    text: string;
    onClick: () => any;
    disabled?: boolean;
  };

  titleSize?: string;
  description?: ReactNode | string;
  requirement?: string;
  input?: {
    type: string;
    name: string;
    placeholder?: string;
  };
  checkboxes?: {
    name: string;
    options: {
      name: string;
      value: string;
    }[];
  };
  button2?: {
    text: string;
    onClick: () => any;
  };
  rules?: any;
  isActive?: boolean;
  stepStyle?: string;
}

const Screen = ({
  register,
  title,
  button1,
  titleSize = '32px',
  description = null,
  requirement = null,
  input = null,
  checkboxes = null,
  button2 = null,
  rules = {},
  isActive = false,
  stepStyle,
}: Props) => {
  return (
    <div className={`screen ${isActive ? 'active' : ''} ${checkboxes && 'checkboxs'}`}>
      <h2 className={`title ${stepStyle}`} style={{ fontSize: titleSize }}>
        {title}
      </h2>

      <div className="content wide">
        {input && (
          <input
            type={input.type}
            placeholder={input.placeholder || ''}
            autoComplete="off"
            {...register(input.name, rules)}
          />
        )}

        {checkboxes && (
          <div className="checkbox_list">
            {checkboxes.options.map((item, index) => (
              <label
                key={item.name}
                className={`checkbox pointer ${
                  index <= checkboxes.options.length / 2 ? 'column1' : 'column2'
                }`}
              >
                <p>{item.name}</p>
                <input
                  type="checkbox"
                  name={checkboxes.name}
                  value={item.value}
                  id={item.value}
                  {...register(checkboxes.name, rules)}
                />
              </label>
            ))}
            <OtherCheckbox register={register} name={checkboxes.name} rules={rules} />
          </div>
        )}

        {description && <p className="description">{description}</p>}

        {requirement && <p className="requirement">{requirement}</p>}
      </div>

      <div className="buttons">
        {button2 && (
          <button type="button" className="form-btn form-btn__2" onClick={button2.onClick}>
            {button2.text}
          </button>
        )}
        <button
          type="button"
          className="form-btn form-btn__1"
          onClick={button1.onClick}
          disabled={button1.disabled || false}
        >
          {button1.text}
        </button>
      </div>
    </div>
  );
};
export default Screen;
