import React from 'react';
import { withPrefix } from 'gatsby';

const Icon = (props) => {
  const { name } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      className={`icon icon-${name}`}
      preserveAspectRatio="none"
      aria-labelledby="boutique software, software house porto, software house aveiro,nearshore"
    >
      <use href={withPrefix(`icons.svg#${name}`)} />
    </svg>
  );
};

export default Icon;
