import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import Screen from './Screen';
import CloseIcon from '../../assets/icons/closeFormButton.inline.svg';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  onClose: () => void;
}

const Form = ({ onClose }: Props) => {
  const [screen, setScreen] = useState(0);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchInputs = watch();

  const validateRequired = (str: string) => {
    return str && str !== '';
  };

  const validateEmail = (email: string) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return email && regex.test(email);
  };

  const validateAreas = (areas: string[]) => {
    return areas && areas.length > 0;
  };

  const onCloseButtonClick = () => {
    onClose();
  };

  const onSubmit = async (data) => {
    const res = await fetch(process.env.GATSBY_FORM_SUBMIT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (res.status === 201) {
      setScreen(5);
    } else {
      toast.error('Backend Error Message! Please, try again in a few seconds!');
    }
  };

  const FormScreens = [
    {
      title: (
        <Fragment>
          <span className="mainTitle">Hi!</span>
        </Fragment>
      ),
      titleSize: '96px',
      description: <Fragment>Share your details, we'll respond shortly.</Fragment>,
      button1: {
        text: 'Next',
        onClick: () => setScreen((prev) => prev + 1),
      },
    },

    {
      title: 'What’s your name?',
      requirement: '*Required',
      input: {
        type: 'text',
        name: 'name',
        placeholder: 'Type your answer',
      },
      rules: {
        required: true,
      },
      button1: {
        text: 'Confirm',
        onClick: () => setScreen((prev) => prev + 1),
        disabled: !validateRequired(watchInputs.name),
      },
    },

    {
      title: 'What’s your email address?',
      requirement: '*Required',
      input: {
        type: 'email',
        name: 'email',
        placeholder: 'Email@example.com',
      },
      button1: {
        text: 'Confirm',
        onClick: () => setScreen((prev) => prev + 1),
        disabled: !validateEmail(watchInputs.email),
      },
      button2: {
        text: 'Go back',
        onClick: () => setScreen((prev) => prev - 1),
      },
    },

    {
      title: 'What company do you work for?',
      requirement: '*Required',
      input: {
        type: 'text',
        name: 'company',
        placeholder: 'Type your answer',
      },
      button1: {
        text: 'Confirm',
        onClick: () => setScreen((prev) => prev + 1),
        disabled: !validateRequired(watchInputs.company),
      },
      button2: {
        text: 'Go back',
        onClick: () => setScreen((prev) => prev - 1),
      },
    },

    {
      title: 'Which area(s) can we help with?',
      requirement: '*Choose one or more',
      checkboxes: {
        name: 'areas',
        options: [
          { name: 'Development', value: 'development' },
          { name: 'Quality Assurance', value: 'qa' },
          { name: 'Cloud', value: 'cloud' },
          { name: 'Data Science', value: 'data-science' },
          { name: 'Infrastructure', value: 'infrastructure' },
          { name: 'UI/UX Design', value: 'ui/ux' },
        ],
      },
      button1: {
        text: 'Submit',
        onClick: handleSubmit(onSubmit),
        disabled: !validateAreas(watchInputs.areas),
      },
      button2: {
        text: 'Go back',
        onClick: () => setScreen((prev) => prev - 1),
      },
    },

    {
      title: (
        <Fragment>
          Thank <span>you!</span>
        </Fragment>
      ),
      titleSize: '96px',
      description: 'We will get back to you as soon as possible.',
      button1: {
        text: 'Close',
        onClick: onCloseButtonClick,
      },
    },
  ];

  return (
    <form className="modal_form" onSubmit={handleSubmit(onSubmit)}>
      {screen > 0 && <p className="screen-info">{`${screen} of ${FormScreens.length - 1}`}</p>}

      <div className="closeButton" onClick={onCloseButtonClick}>
        <CloseIcon />
      </div>

      {FormScreens.map((item, index) => {
        return (
          <Screen
            stepStyle={index === 0 || index === 5 ? 'start' : 'middle'}
            key={index}
            register={register}
            {...item}
            isActive={index === screen}
          />
        );
      })}

      <ToastContainer position="top-right" theme="dark" />
    </form>
  );
};

export default Form;
