import React from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import PortfolioContext from '../../../context/context';

const Alert = () => {
  const { alert, setAlert } = React.useContext(PortfolioContext);

  // TIMER TO CLOSE ALERT
  const [intervalID, setIntervalID] = React.useState(null);
  const [progress, setProgress] = React.useState(0);

  const handleStartTimer = () => {
    const id = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + 0.5;
        }

        clearInterval(id);
        return prev;
      });
    }, 20);

    setIntervalID(id);
  };

  const handlePauseTimer = () => {
    clearInterval(intervalID);
  };

  const handleCloseAlert = () => {
    handlePauseTimer();
    setProgress(0);
    setTimeout(() => {
      setAlert({ ...alert, isVisible: false });
    }, 400);
  };

  React.useEffect(() => {
    if (progress === 100) {
      handleCloseAlert();
    }
  }, [progress]);

  React.useEffect(() => {
    if (alert.isVisible) {
      handleStartTimer();
    }
  }, [alert]);

  return (
    <div className="alert-notification">
      {alert.isVisible && (
        <BootstrapAlert
          className="d-flex justify-content-between align-items-center"
          onMouseEnter={handlePauseTimer}
          onMouseLeave={handleStartTimer}
          variant={alert.variant}
          onClose={() => handleCloseAlert()}
          dismissible
        >
          {alert.message}
        </BootstrapAlert>
      )}
    </div>
  );
};

export default Alert;
