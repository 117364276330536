import ReactGA from "react-ga4";
import { isCookiesConsented } from "./cookies";

export const initializeGa4 = () => {
  if(!isCookiesConsented) return;
  ReactGA.initialize(process.env.GATSBY_GA4);
}

interface IPageView {
  page: string, //e.g. "/page/1"
  title: string, //e.g. "Page Title 1"
}

export const sendPageView = ({page,title}:IPageView) => {
  if(!isCookiesConsented) return;
  ReactGA.send({
    hitType: "pageview",
    page: page,
    title: title
  });
}

interface IEvent {
  action: string, //e.g. "Button click"
  category: string, //e.g. "Header button"
  label: string //e.g. "Button"
}

export const sendEvent = ({action, category, label}: IEvent) => {
  if(!isCookiesConsented) return;
  ReactGA.event({
    action: action,
    category: category,
    label: label,
  });
}