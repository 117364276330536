import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const MainWidth = ({ children }) => (
  <Container fluid className="nopadding ">
    <Row className="justify-content-md-center nopadding ">
      <Col xl={11} className="nopadding wfull">
        {children}
      </Col>
    </Row>
  </Container>
);

export default MainWidth;
